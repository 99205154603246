import React from "react";
import { graphql } from "gatsby";
import { Section, SectionTitle } from "../Section";
import { TechTextList } from "../Technologies";
import { getVacanciesUrl, getVacancyUrl } from "../../utils/urls";
import { Technology } from "../../utils/tech";
import { MdxImageSharp } from "../../types/images";
import {
  Card,
  CardBody,
  CardGrid,
  CardImage,
  CardInner,
  CardTitle,
} from "../Card";
import { Image } from "../Image";
import { Link } from "../Link";

export type MdxVacanciesData = {
  nodes: {
    excerpt: string;
    fields: {
      slug: string;
    };
    frontmatter: {
      date: string;
      title: string;
      description: string;
      tech: Technology[];
      experience: string;
      featured: {
        publicURL: string;
      } & MdxImageSharp;
    };
  }[];
};

export const mdxVacanciesQuery = graphql`
  fragment VacanciesData on MdxConnection {
    nodes {
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tech
        experience
        featured {
          publicURL
          ...CardImageData
        }
      }
    }
  }
`;

type Props = { vacanciesData: MdxVacanciesData };

export const MdxVacancies: React.FC<Props> = ({ vacanciesData }) => {
  const nodes = vacanciesData.nodes;

  return (
    <CardGrid>
      {nodes.map((node, i) => {
        if (!node || !node.frontmatter || !node.fields || !node.fields.slug)
          return null;
        const { frontmatter, fields } = node;

        const title = frontmatter.title || fields.slug;
        const url = getVacancyUrl(fields.slug || "");
        return (
          <Card key={fields?.slug || i}>
            <CardImage>
              <Link to={url}>
                <Image image={frontmatter.featured} alt={title} />
              </Link>
            </CardImage>
            <CardTitle>
              <Link to={url}> {title}</Link>
            </CardTitle>
            <CardInner>
              <small>
                {frontmatter.experience}
                <TechTextList tech={frontmatter.tech} />
              </small>
            </CardInner>
            <CardBody>{frontmatter.description || node.excerpt}</CardBody>
          </Card>
        );
      })}
    </CardGrid>
  );
};
