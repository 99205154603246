import React from "react";
import { Layout } from "../../components/Layout";
import { graphql, useStaticQuery } from "gatsby";
import { Seo } from "../../components/Seo";
import { Divider } from "../../components/Divider";
import { JoinLeanyLabsForm } from "../../components/Vacancies/JoinLeanyLabsForm";
import { PageFeatured } from "../../components/PageFeatured";
import { Link } from "../../components/Link";
import {
  MdxVacancies,
  MdxVacanciesData,
} from "../../components/Vacancies/MdxVacancies";
import { Container } from "../../components/Section";
import { PageHeader, PageTitle } from "../../components/PageHeader";

const Page: React.FC = () => {
  const data = useStaticQuery<{ allMdx: MdxVacanciesData }>(graphql`
    query {
      allMdx(
        filter: {
          fields: { collection: { eq: "vacancies" } }
          frontmatter: { open: { eq: true } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        ...VacanciesData
      }
    }
  `);

  return (
    <Layout noContact>
      <Seo
        ogFullTitle
        title="Join LeanyLabs"
        description="Join the team of professionals that deliver awesome software."
      />
      <Container>
        <PageHeader>
          <PageTitle>Join LeanyLabs</PageTitle>
        </PageHeader>
        <p>
          We're always looking for talented people who will help deliver
          fantastic projects together and provide real value to the world.
        </p>
        <p>
          Don't hesitate to{" "}
          <Link
            ext
            to="https://www.linkedin.com/in/kateryna-semeniuk-0b9b551a1/"
          >
            chat with us on LinkedIn{" "}
          </Link>{" "}
          and find out more about the latest opportunities at LeanyLabs, fill
          out the <Link to="#contact-us">form below</Link>, or send your CV with
          a short cover letter to{" "}
          <Link
            ext
            to="mailto:hr@leanylabs.com?subject=Let's%20deliver%20web%20apps%20together!"
          >
            hr@leanylabs.com
          </Link>
          , and we'll get back to you.
        </p>
        {!!data.allMdx.nodes?.length && <h2>Open Positions</h2>}
        <MdxVacancies vacanciesData={data.allMdx} />
        <Divider />
      </Container>
      <JoinLeanyLabsForm contact />
    </Layout>
  );
};

export default Page;
